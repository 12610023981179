import { OpenAPI } from "./api/core/OpenAPI";

export { ApiError } from "./api/core/ApiError";
export { CancelablePromise, CancelError } from "./api/core/CancelablePromise";
export { type OpenAPIConfig } from "./api/core/OpenAPI";
export * from "./api/schemas.gen";
export * from "./api/services.gen";
export * from "./api/types.gen";

let BASE: string;
if (process.env.NEXT_PUBLIC_BYOC_MODE_ENABLED === "true") {
  // will be replaced by the real URL in the runtime by the proxy
  if (typeof window !== "undefined") {
    BASE = "";
  } else {
    BASE = process.env.BACKEND_URL ?? "";
    OpenAPI.TOKEN = process.env.NEXT_PUBLIC_REAL_TOKEN ?? undefined;
    OpenAPI.interceptors.request.use(async (config) => {
      const { headers } = await import("next/headers");
      const cookie = (await headers()).get("cookie");
      if (!cookie) {
        return config;
      }
      // @ts-ignore
      const session = cookie!
        .split(";")
        .find((c) => c.trim().startsWith("session="))
        ?.split("=")[1]
        .trim();
      if (session) {
        config.headers = {
          ...config.headers,
          Cookie: `session=${session}`,
        };
      }

      return config;
    });
    OpenAPI.interceptors.response.use(async (response) => {
      if (!response.ok) {
        console.error(
          "API error when fetching",
          response.url,
          response.status,
          response.statusText,
        );
      }
      return response;
    });
  }
} else {
  if (typeof window === "undefined") {
    BASE =
      process.env.NEXT_PUBLIC_REAL_BACKEND_URL ??
      process.env.NEXT_PUBLIC_BACKEND_URL ??
      "";
    OpenAPI.TOKEN = process.env.NEXT_PUBLIC_REAL_TOKEN ?? undefined;
    OpenAPI.interceptors.request.use(async (config) => {
      const { headers } = await import("next/headers");
      const cookie = (await headers()).get("cookie");
      if (!cookie) {
        return config;
      }
      // @ts-ignore
      const session = cookie!
        .split(";")
        .find((c) => c.trim().startsWith("session="))
        ?.split("=")[1]
        .trim();
      if (session) {
        config.headers = {
          ...config.headers,
          Cookie: `session=${session}`,
        };
      }

      return config;
    });
    OpenAPI.interceptors.response.use(async (response) => {
      if (!response.ok) {
        console.error(
          "API error when fetching",
          response.url,
          response.status,
          response.statusText,
        );
      }
      return response;
    });
  } else {
    BASE =
      localStorage.getItem("openapi.base") ??
      process.env.NEXT_PUBLIC_REAL_BACKEND_URL ??
      process.env.NEXT_PUBLIC_BACKEND_URL ??
      "";
    OpenAPI.TOKEN =
      localStorage.getItem("openapi.token") ??
      process.env.NEXT_PUBLIC_REAL_TOKEN ??
      undefined;

    OpenAPI.interceptors.response.use(async (response) => {
      if (response.headers.get('Content-Type') === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        const headers = new Headers(response.headers);
        headers.set('Content-Type', 'application/octet-stream');
        return new Response(response.body, {
          ...response,
          headers,
        });
      }
      return response;
    })
  }
}

OpenAPI.BASE = BASE;
OpenAPI.WITH_CREDENTIALS = true;

export { OpenAPI };
